import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import PostTags from "../components/PostTags/PostTags";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";

export default class PostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }

    return (
      <Layout>
        <div className="blog-container">
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />
          <section>
            <div className="section hero is-medium is-primary">
              <h1 className="has-text-centered is-centered is-size-1-widescreen is-size-2-tablet is-size-3-mobile title">{post.title}</h1>
            </div>
            <section className="hero is-medium">
              <div className="hero-body content">
                <div className="container">
                  <div className="columns is-4 is-variable is-centered">
                    <div className="column is-three-fifths-desktop">
                      <div className="post-body" dangerouslySetInnerHTML={{ __html: postNode.html }} />
                      <div className="subtitle is-size-6">
                        <br></br>
                        <div className="is-size-6 is-clearfix" style={{marginBottom: '.25rem'}}>Author: {post.author}</div>
                        <div className="is-size-6">Posted:{' '}{post.date}</div>
                      </div>
                      <hr></hr>
                      <div className="post-meta">
                        <PostTags tags={post.tags} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        author
        category
        tags
      }
      fields {
        slug
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
